h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Merriweather Sans", sans-serif;
}

.App {
  text-align: center;
  font-family: "Helvetica", Arial, sans-serif;
}

// Color Palette
// #e3fdfd
// #cbf1f5
// #a6e3e9
// #71c9ce;

// Bootstrap Stylings
.navbar {
  background-color: #71c9ce;
  color: white;
  padding: 8% 10%;
  .navbar-toggler {
    border-width: 2px;
  }
  &.navbar-dark {
    .navbar-brand {
      font-family: "Merriweather Sans", sans-serif;
    }
    .navbar-nav {
      text-align: left;
    }
    .nav-link {
      color: rgba(255, 255, 255, 0.9);
      margin-left: 15px;
    }
  }
}

.container-fluid {
  padding: 6% 15%;
}

.jumbotron {
  background-color: #71c9ce;
  border-radius: 0;
  padding-bottom: 9%;
  margin: 0;
  &.c-jumbotron {
    .c-jumbotron--title {
      font-family: monospace;
      font-size: 4rem;
      font-weight: 700;
    }
    .c-jumbotron--subtitle {
      color: white;
      font-family: monospace;
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
}

// Font Awesome Stylings
.font-awesome-skill {
  color: #71c9ce;
  font-size: 3rem;
}

.fa-terminal {
  font-size: 0.7rem;
  font-weight: 900;
}

// Component Stylings
.c-tech-skills {
  //padding-bottom: 2%;
  .c-tech-skills--title {
    font-family: "Merriweather Sans", sans-serif;
    padding: 2rem;
  }
  .row {
    margin: 0 auto;
    max-width: 1000px;
    padding: 1.5rem 0;
  }
  .c-tech-skills--skill {
    margin: 10px 0;
  }
  .c-tech-skills--skill-description {
    font-size: 1rem;
    font-family: "Helvetica", Arial, sans-serif;
    margin: 8px 2px;
    display: inline-block;
  }
}

.c-about {
  text-align: left;
  .c-about--title {
    font-family: "Merriweather Sans", sans-serif;
    font-size: 1.5rem;
    margin: 1rem 0;
  }
  .c-about--desc {
    color: #3f3f3f;
    margin-bottom: 1.5rem;
  }
}

.c-projects {
  background-color: #a6e3e9;
  .c-project__item {
    padding: 2rem;
    &:hover {
      .c-project__item-desc {
        text-decoration: underline;
      }
    }
  }
  .c-project__item-img {
    height: 100px;
  }
  .c-project__item-desc {
    font-size: 18px;
    font-weight: 700;
  }
}

.c-cta {
  text-align: center;
  padding-top: 0;
  .c-cta--title {
    font-family: "Merriweather Sans", sans-serif;
    margin: 1rem 0;
  }
  .c-cta--desc {
    color: #3f3f3f;
    margin-bottom: 1.5rem;
  }
}

.c-contact-me {
  background-color: #a6e3e9;
  padding: 5% 15% 2%;
  .c-contact-me--icon {
    display: inline-block;
    font-size: 1rem;
    padding: 2.2rem 0 1rem;
    margin: 0 10px;
    a {
      color: #3f3f3f;
      &:hover {
        color: #fff;
        text-decoration: none;
      }
    }
  }
}
